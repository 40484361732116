
import { computed, defineComponent, ref } from 'vue';
import LessonCard from '@/components/elements/LessonCard.vue';
import UploadCard from '@/components/elements/UploadCard.vue';
import VideoDatabaseEntry from '@/model/VideoDatabaseEntry';
import miniLessonManager, { MiniLessonManager } from '@/services/MiniLessonManager';
import db from '@/services/VideoDatabase';
import MiniLesson from '@/model/MiniLesson';
import workflowManager, { WorkflowManager } from '@/services/WorkflowManager';

const Tabs = Object.freeze({
  Workflows: 'Workflows',
  Videos: 'Videos',
  Tools: 'Tools',
});
const TabSet = Object.freeze(new Set(Object.values(Tabs)));
const TabList = Object.freeze(new Array(...Object.values(Tabs)));

export default defineComponent({
  name: 'MainMenu',
  emits: [
    'lesson-selected',
    'create-lesson-selected',
    'workflow-selected',
    'create-workflow-selected',
    'keyframeselectortool-selected',
  ],
  components: {
    LessonCard,
    UploadCard,
  },
  setup(props, ctx) {
    const videos = db.entries;
    const selectedVideo = ref(null as VideoDatabaseEntry | null);
    const uploadLessonUIActive = ref(false);
    const currentTab = ref(Tabs.Workflows);
    const activeTags = ref(new Set());

    function onLessonSelected(
      videoEntry: VideoDatabaseEntry,
      lesson: MiniLesson,
    ) {
      ctx.emit('lesson-selected', videoEntry, lesson);
      selectedVideo.value = null;
    }

    function createLessonSelected(videoEntry: VideoDatabaseEntry) {
      ctx.emit('create-lesson-selected', videoEntry);
      selectedVideo.value = null;
    }

    const filteredVideos = computed(() => {
      const tagMatchingMotions = videos.value.filter((videoEntry) => {

        if (activeTags.value.size === 0) return true;

        const allTagsMatch = videoEntry.tags.reduce((someTagMatches: boolean, currTag: string) => {
          const thisTagMatches = activeTags.value.has(currTag);
          return someTagMatches || thisTagMatches;
        }, false);

        return allTagsMatch;

      });

      return tagMatchingMotions;
    });

    return {
      workflows: workflowManager.allWorkflows,
      selectedVideo,
      videos,
      filteredVideos,
      onLessonSelected,
      createLessonSelected,
      uploadLessonUIActive,
      uploadWorkflowUIActive: ref(false),
      workflowManager,
      activeTags,
      availableTags: db.allTags,
      TabList,
      Tabs,
      currentTab,
    };
  },
  methods: {
    copyLink(workflowId: string, event: MouseEvent) {
      const url = `https://${window.location.host}?workflowId=${workflowId}&participantId=PARTICIPANTID`;
      navigator.clipboard.writeText(url);

      const target = event?.target as (HTMLButtonElement | undefined);
      if (target) {
        target.classList.add('is-success');
        const originalText = target.innerText;
        target.innerText = 'Copied!';
        target.disabled = true;

        setTimeout(() => {
          if (target) {
            target.classList.remove('is-success');
            target.innerText = originalText;
            target.disabled = false;
          }
        }, 1000);
      }
    },
    toggleTag(tag: string) {
      if (this.activeTags.has(tag)) {
        this.activeTags.delete(tag);
      } else {
        this.activeTags.add(tag);
      }
    },
    setTab(tab: string) {
      console.log('Switching to tab:', tab);
      if (!TabSet.has(tab)) {
        console.error(`Tab ${tab} not recognized!`);
        return;
      }
      this.currentTab = tab;
    },
    onKeyframeSelectorToolSelected(videoEntry: VideoDatabaseEntry, keyframes: number[]) {
      this.$emit('keyframeselectortool-selected', videoEntry, keyframes);
    },
    async uploadLessons(files: FileList) {
      console.log('Uploading lessons', files);

      for (let i = 0; i < files.length; i += 1) {
        const file = files.item(i);
        if (!file) continue;
        // eslint-disable-next-line no-await-in-loop
        const text = await file.text();
        const lesson = JSON.parse(text);
        MiniLessonManager.validateLesson(lesson);
        miniLessonManager.saveCustomLesson(lesson);
      }
      return true;
    },
    async uploadWorkflows(files: FileList) {
      console.log('Uploading workflows', files);

      for (let i = 0; i < files.length; i += 1) {
        const file = files.item(i);
        if (!file) continue;
        // eslint-disable-next-line no-await-in-loop
        const text = await file.text();
        const workflow = JSON.parse(text);
        WorkflowManager.validateWorkflow(workflow);

        if (workflowManager.hasBakedInWorkflow(workflow.id)) throw new Error(`Cannot overwrite baked in workflow ${workflowManager.workflows.get(workflow.id)?.title}`);

        // eslint-disable-next-line no-alert
        if (!workflowManager.hasWorkflow(workflow.id) || window.confirm(`Are you sure you want to overwrite workflow '${workflowManager.workflows.get(workflow.id)?.title}'?`)) {
          workflowManager.upsertCustomWorkflow(workflow);
        }
      }
      return true;
    },
  },
});
